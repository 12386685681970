import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import image1 from './assets/4.png';
import image2 from './assets/1.jpeg';
import image3 from './assets/2.jpeg';
import image4 from './assets/3.jpeg';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

function ListImage (props) {
  return (
    <Carousel
      showStatus={false}
      showIndicators={false}
      autoPlay
      autoFocus
      dynamicHeight
      infiniteLoop
    >
       <div>
        <img src={image1}  alt="" />
      </div>
      <div>
        <img src={image3} alt="" />
      </div>
      <div>
        <img src={image2} alt="" />
      </div>
      <div>
        <img src={image4} alt="" />
      </div>
     
    </Carousel>
  );
}

export default ListImage;
