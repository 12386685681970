import axios from 'axios';
const ApiRequest = axios.create ({
  baseURL: 'https://business.pleasanter.vn/api/items',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=utf-8',
  },
});

const ApiKey = {
  ApiVersion: 1.1,
  ApiKey: '049ae0abad7d09e642ee7eed609974d0dfac7e9874868e8a8089e96f8cc006cc57f13c5adb6b685b0eb32cbe020b3ecee2c908ed00b1e64a510b2516842094bb',
};

export function getData () {
  return ApiRequest.post('/1159/get', ApiKey);
}
export function updateData(body) {
  return ApiRequest.post('/1159/update', body);
}
