import React, {useState, useEffect} from 'react';
import './App.css';
import Map from './Map/MapFloor2';
import Modal from './Popup';
import {message} from 'antd';
import {SyncOutlined} from '@ant-design/icons';
import styled from 'styled-components';
import {getData} from './api';
function App () {
  const [visible, setvisible] = useState (false);
  const [loading, setloading] = useState (false);
  const [dataRespone, setdataRespone] = useState ({});
  useEffect (() => {
    setloading (true);
    apiGetData ();
  }, []);
  const apiGetData = async () => {
    try {
      await getData().then (res => {
        setdataRespone(res.data.Response.Data[0]);
        setloading (false);
      });
    } catch (error) {
      setloading (false);
      message.error ('エラーが発生しました。', 3);
    }
  };
  const openPopup = () => {
    setvisible (true);
  };
  const closePopup = () => {
    setvisible (false);
  };
  return (
    <div>
      <Modal visible={visible} setVisible={closePopup} dataRespone={dataRespone} setdataRespone={(data) =>setdataRespone(data)}/>
      <Header>
        ショッピングモール地図
      </Header>
      {loading
        ? <LoadingLayout>
            <SyncOutlined spin style={{fontSize: 30}} />
          </LoadingLayout>
        : <div>
            <SVG
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="643.99"
              height="1078.19"
              viewBox="0 0 643.99 1078.19"
            >
              <Map onClick={openPopup} dataRespone={dataRespone} />
            </SVG>
          </div>}
    </div>
  );
}

const Header = styled.h1`
  text-align: center;
`;
const SVG = styled.svg`
  width: 100%;
  padding-left: 10px;
`;
const LoadingLayout = styled.div`
  display:flex;
  flex:1;
  justify-content: center;
  align-items: center;
`;
export default App;
