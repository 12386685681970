import React, {useState, useEffect} from 'react';
import {Button, Input, message, Modal, Select} from 'antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ListImage from './ListImage';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { updateData } from './api';
function Popup (props) {
  const { Option } = Select;
  const {visible, setVisible, dataRespone, setdataRespone} = props;
  const [nameStore, setnameStore] = useState();
  const [comment, setcomment] = useState();
  const [status, setstatus] = useState();
  const [loading, setloading] = useState(false);
  useEffect(() => {
    setnameStore(dataRespone.ClassHash?.ClassA);
    setcomment(dataRespone.DescriptionHash?.DescriptionA);
    setstatus(dataRespone.ClassHash?.ClassB);
  }, [dataRespone]);
  // const handle3DView = () => {
  //   const url = 'https://thinhbx0311.github.io/WEBGL/';
  //   const win = window.open (url, '_blank');
  //   win.focus ();
  // };

  const handleBooking = () => {
    const url = 'https://business.pleasanter.vn/items/1159';
    const win = window.open (url, '_blank');
    win.focus ();
  };
  const handleSave = async () => {
    if (nameStore.trim() !== '' && status.trim() !== ''){
      setloading(true);
      const body = {
        ApiVersion: 1.1,
        ApiKey: '049ae0abad7d09e642ee7eed609974d0dfac7e9874868e8a8089e96f8cc006cc57f13c5adb6b685b0eb32cbe020b3ecee2c908ed00b1e64a510b2516842094bb',
        ClassHash: {
          ClassA: nameStore,
          ClassB: status,
        },
        DescriptionHash: {
          DescriptionA: comment.toString(),
        }
      }
      try {
        await updateData(body).then(res => {
          console.log(res.data);
          const newData = {...dataRespone,  
            ClassHash: {
            ClassA: nameStore,
            ClassB: status,
            }, 
            DescriptionHash: {
              DescriptionA: comment.toString(),
              DescriptionB: dataRespone.DescriptionHash.DescriptionB
            }
          }
          setdataRespone(newData);
          setloading(false);
          setVisible();
        })
      } catch (error) {
        setloading (false);
        message.error ('エラーが発生しました。', 3);
      }
    }
  }
  
  const onCancel = () => {
    setVisible();
    setnameStore(dataRespone.ClassHash?.ClassA);
    setcomment(dataRespone.DescriptionHash?.DescriptionA);
    setstatus(dataRespone.ClassHash?.ClassB);
  }
  return (
    <div>
      <Modal
        visible={visible}
        onCancel={onCancel}
        width={'80%'}
        centered
        footer={null}
      >
        <div>
          <ContainerLayout>
            <ImageLayout>
              <ListImage />
            </ImageLayout>
            <ContentLayout>
              <Title>テナント概要</Title>
              <Table>
                <Tr>
                  <Td>
                    現店舗名
                  </Td>
                  <Td>
                      <Input
                        style={{width:330}}
                        size='small' 
                        value={nameStore} 
                        onChange={(e) => setnameStore(e.target.value)} />
                  </Td>
                </Tr>
                <Tr>
                <Td style={{display:'flex', flex:1}}>
                  コメント
                  </Td>
                  <Td>
                   
                    <Input.TextArea      
                      style={{width:'100%'}}    
                      autoSize={{ minRows: 6, maxRows: 6 }}
                      cols={6}
                      value={comment} 
                      onChange={(e) => setcomment(e.target.value)} />
                  </Td>
                </Tr>
                <Tr>
                  <Td>
                  状況
                  </Td>
                  <Td>
                    <Select defaultValue={status}
                      style={{minWidth: '50%'}}
                      value={status}
                      onChange={(value) => setstatus(value)}>
                      <Option value="空き店舗">空き店舗</Option>
                      <Option value="予約済み、未契約">予約済み、未契約</Option>
                      <Option value="契約済み">契約済み</Option>
                      </Select>
                  </Td>
                </Tr>
              </Table>
              <ButtonLayout2>
            <ButtonLayout1>
              <ButtonStyle1 type="primary" style={{backgroundColor:'red', border: 'none'}} size="large" onClick={onCancel}>
                キャンセル
              </ButtonStyle1>
              <ButtonStyle1
                type="primary"
                size="large"
                loading={loading}
                onClick={handleSave}
              >
                保存
              </ButtonStyle1>
            </ButtonLayout1>
          </ButtonLayout2>
            </ContentLayout>
          </ContainerLayout>
          <ButtonLayout>
              <ButtonStyle type="primary" size="large" onClick={handleBooking}>
                物件管理システムへ
              </ButtonStyle>
          </ButtonLayout>
        </div>
      </Modal>
    </div>
  );
}

Popup.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func,
  dataRespone: PropTypes.object,
  setdataRespone: PropTypes.func,
};

const ContainerLayout = styled.div`
  margin-top: 20px;
  flex:1;
  display: flex;
  flex-direction: row;
  flex-wrap:wrap;
`;
const ButtonLayout = styled.div`
  display: flex;
  justify-content:center;
  padding-bottom: 20px;
`;
const ButtonLayout2 = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  justify-content:center;
  flex-direction: row;
`;
const ButtonLayout1 = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content:space-evenly;
  flex-wrap:wrap;
  align-items: center;

`;
const ImageLayout = styled.div`
   width:80%;
   flex:1;
   align-items:center;
   justify-content: center;
`;

const ContentLayout = styled.div`
flex:1;
padding-left: 40px;
justify-content: center;
align-items: center;
display:flex;
flex-direction:column;
`;
const Title = styled.h1`
  text-align: center;
`;
const ButtonStyle = styled (Button)`
  white-space: normal;
  border-radius: 8px;
  width: 180px;
  height: 50px;
`;
const ButtonStyle1 = styled (Button)`
  white-space: normal;
  border-radius: 8px;
  width: 120px;

`;
const Table = styled.table`
  min-width: 40%;

`;
const Td = styled.td`
  text-align: left;
  min-width: 70px;
  align-self:flex-start;
  padding-bottom: 10px;
  `;
const Tr = styled.tr`
  margin-bottom: 20px;
`
export default Popup;
